<template>
  <div class="pa-10">
    <div>
      <h2 class="mb-5 color_default">
        Grafik
      </h2>
      <v-card class="mt-10 custom_card">
        <v-tabs
          background-color="#cdf0f7"
          hide-slider
          class="pb-2"
        >
          <v-tab
            v-for="(item, index) in tabsTitle"
            :key="index"
            active-class="active_tab"
            :class="
              index !== tabsTitle.length - 1
                ? 'text-capitalize mr-1 header_data mb-1'
                : 'text-capitalize header_data mb-1'
            "
          >
            {{ item }}
          </v-tab>
          <v-tab-item>
            <GraphicEnviromentalHealthKey />
          </v-tab-item>
          <v-tab-item>
            <GraphicWaste />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </div>
</template>
<script>
import GraphicEnviromentalHealthKey from "./EnvironmentalHealthKey/Index.vue"
import GraphicWaste from "./Waste/Index.vue"

export default {
  name: "GraphicIndex",
  components: {
    GraphicEnviromentalHealthKey, GraphicWaste
  },
  data: () => {
    return {
      tabsTitle: ["Kesling Kunci", "Kelola Limbah"]
    };
  },
};
</script>
<style lang="scss">

.header_data {
  color: #00b4cc !important;
  background: #fff;
  width: calc(100% / 5);
  border-radius: 13px 13px 0 0;
}
.active_tab {
  background: #00b4cc;
  color: #fff !important;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
</style>
