<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="8"
      fill="#31B4CC"
    />
    <path
      d="M15.5 26C14.2591 26 13.1987 25.5708 12.3189 24.7123C11.4391 23.8538 10.9995 22.8045 11 21.5645C11 20.5016 11.3205 19.5545 11.9614 18.7233C12.6023 17.892 13.4409 17.3606 14.4773 17.1289C14.7091 16.1478 15.2886 15.2144 16.2159 14.3286C17.1432 13.4429 18.1318 13 19.1818 13C19.6318 13 20.0172 13.1603 20.3379 13.4808C20.6586 13.8013 20.8187 14.1861 20.8182 14.6352V19.5818L22.1273 18.3145L23.2727 19.4591L20 22.7296L16.7273 19.4591L17.8727 18.3145L19.1818 19.5818V14.6352C18.1455 14.826 17.3409 15.3269 16.7682 16.138C16.1955 16.9491 15.9091 17.7836 15.9091 18.6415H15.5C14.7091 18.6415 14.0341 18.9209 13.475 19.4796C12.9159 20.0383 12.6364 20.7128 12.6364 21.5031C12.6364 22.2935 12.9159 22.968 13.475 23.5267C14.0341 24.0854 14.7091 24.3648 15.5 24.3648H25.3182C25.8909 24.3648 26.375 24.1672 26.7705 23.772C27.1659 23.3768 27.3636 22.8931 27.3636 22.3208C27.3636 21.7484 27.1659 21.2647 26.7705 20.8695C26.375 20.4743 25.8909 20.2767 25.3182 20.2767H24.0909V18.6415C24.0909 17.9874 23.9409 17.3775 23.6409 16.8117C23.3409 16.2459 22.9455 15.7657 22.4545 15.3711V13.4701C23.4636 13.9471 24.2614 14.6524 24.8477 15.5861C25.4341 16.5198 25.7273 17.5383 25.7273 18.6415C26.6682 18.7505 27.449 19.1561 28.0697 19.8581C28.6905 20.5602 29.0005 21.381 29 22.3208C29 23.3428 28.6419 24.2116 27.9257 24.9273C27.2095 25.643 26.3404 26.0005 25.3182 26H15.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
    name: "Print",
}
</script>