<template>
  <div class="container">
    <div class="header">
      <div class="graphic-title">
        {{ title }}
      </div>
      <div class="justify-end d-flex align-center">
        <div
          class="button-print"
          @click="print"
        >
          <IconPrint />
        </div>
      </div>
    </div>

    <div
      v-if=" chart_options && series.length > 0"
      class="my-5 graphic-card"
    >
      <div
        id="chart"
        class="chart-container"
      >
        <apexchart
          type="donut"
          :options="chart_options"
          :series="series"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <div
      v-else
      class="justify-center d-flex align-center"
      style="height: 200px"
    >
      <h3>Data Tidak Ditemukan</h3>
    </div>
    <div
      v-if="chart_options"
      class="pa-3"
    >
      <print
        :show.sync="dialog_print"
        :options="chart_options"
        :title="print_title"
        :type="type"
        @close="dialog_print = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COLORS } from "../../../constants/colors";
import Print from "../PrintWaste.vue";
import IconPrint from "../../../components/icons/Print.vue";

export default {
  name: "GraphicDonutWaste",
  components: {
    Print,
    IconPrint,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    series: {
      type: Array,
      default: () => [],
    },
    provinceName: {
      type: String,
      default: "",
    },
    regencyName: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      COLORS,
      chart_options: {
        chart: {
          fontFamily: "Montserrat",
          type: "donut",
          title: this.title,
        },
        series: this.series,
        labels: [
          "Sesuai Kriteria",
          "Tidak Sesuai Kriteria",
          
        ],
        colors: [
          "#31B4CC",
          "#C4CFD9",
        ],
        plotOptions: {
          pie: {
            donut: {
              // size: "65%",
              labels: {
                show: false,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: COLORS.primary_blue,
                  offsetY: -10,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },
        },
        legend: {
          position: "right",
          horizontalAlign: "center",
        },
      },
      dialog_print: false,
      print_title: "",
    };
  },
  computed: {
    ...mapState({
      summary: (state) => state.report.monthly_summary,
    }),
  },
  methods: {
    print() {
      if (this.chart_options) {
        if (this.chart_options.legend.width) {
          delete this.chart_options.legend.width;
        }

        this.print_title = `${
          this.provinceName ? this.provinceName : "Nasional"
        } - ${this.$date(this.date).format("MMMM YYYY")}`;
      
        this.dialog_print = true;
      }
    },
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  border: 1px solid #e7ecf1;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
  padding-bottom: 0px;
}

.chart-container {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
}

.graphic-title {
  /* margin: 20px; */
  margin-left: 10px;
  font-weight: 525;
}

.graphic-card {
  background: #fff;
  border-radius: 13px;
}

.button-print {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-print:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>