<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div
          v-if="provinces"
          class="toolbar_items"
        >
          <span class="dropdown-label">Provinsi</span>
          <v-select
            v-if="
              role === ROLES.PublicHealthService_Regency ||
                role === ROLES.PublicHealthService_Province
            "
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
          <v-select
            v-else
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            v-model="selected_regency_id"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          />
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Tahun - Bulan</span>
          <v-menu
            ref="menu"
            v-model="menu_date"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-calendar"
                placeholder="--- ------"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                class="mt-1"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              color="primary"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            height="40px"
            dark
            min-width="10px"
            @click="fetchSummary"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-5">
        <v-row
          v-for="(chunk, index) in chunkedData"
          :key="index"
        >
          <v-col
            v-for="(item, chunk_index) in chunk"
            :key="chunk_index"
            cols="4"
          >
            <GraphicDonutWaste 
              :title="item.label" 
              :type="item.type"
              :series="generateSeries(item.data)" 
              :province-name="getProvinceById(selected_province_id)"
              :regency-name="getRegencyById(selected_regency_id)"
              :date="date"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { ROLES } from "../../../constants/constant";
import GraphicDonutWaste from "../../../components/Graphic/Waste/Donut.vue";

export default {
  name: "GraphicWaste",
  components: {
    GraphicDonutWaste,
  },
  data() {
    return {
      ROLES,
      is_loading: false,
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      limit: 10,
      menu_date: false,
      menu_fasyankes_type: false,
      selected_fasyankes_type: "",
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      summary: (state) => state.report.waste_monthly_summary,
    }),
    chunkedData() {
      return _.chunk(this.summary.data, 3);
    },
  },
  watch: {
    selected_province_id() {
      if (
        this.selected_province_id &&
        this.selected_province_id !== "" &&
        this.role !== ROLES.PublicHealthService_Regency
      ) {
        this.selected_regency_id = null;
      }
    },
  },
  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    if (this.profile.access_right === ROLES.PublicHealthService_Regency) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_regency_id = parseInt(this.profile.kota);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    await this.fetchSummary();
    console.log(this.summary);
  },
  methods: {
    async fetchSummary() {
      this.loading = true;
      let request_params = {
        date: this.date,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
      };
      await this.$store.dispatch("report/getWasteManagementMonthlySummary", request_params);
      this.loading = false;
    },
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
        }
        this.cities = cities;
      });
    },
    generateSeries(data) {
      const { meet_criteria, not_meet_criteria } = data;
      return [meet_criteria, not_meet_criteria];
    },
    getProvinceById(id) {
      if (!id) return "";
      const province = this.provinces.find((item) => item.provinsi_id === id);
      return province?.provinsi_name;
    },
    getRegencyById(id) {
      if (!id) return "";
      const regency = this.cities.find((item) => item.kabupatenKota_id === id);
      return regency?.kabupaten_name;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.dropdown-label {
  font-size: 0.75rem;
  font-weight: 550;
}

.paginate-buttons {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
}

.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}

.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}

.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}

.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}

.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}

.bottom-bar {
  width: calc(100% / 3);
}

.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}

.table-actions {
  display: flex;
  gap: 10px;
}

.service-indicators h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.service-indicators {
  margin-top: 20px;
  font-size: 12px;
}
</style>
