<template>
  <v-row justify="start">
    <v-dialog
      v-model="show"
      persistent
      max-width="550px"
    >
      <v-card
        class="rounded-max hide_overflow"
        flat
      >
        <div class="closer d-flex justify-end pa-1">
          <v-btn
            icon
            small
            @click="$emit('close')"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="">
          <div ref="printContainer">
            <div style="height: 5px" />
            <div
              v-if="options"
              class="pa-2"
            >
              <v-card
                outlined
                class="pa-3 rounded-lg"
              >
                <p class="ma-0 text-center">
                  {{ title }}
                </p>
                <v-divider />
                <h3 class="text-center mt-4">
                  {{ options.chart.title }}
                </h3>
                <apexchart
                  type="donut"
                  height="300"
                  :options="options"
                  :series="generateSeries()"
                />
                <p class="ma-0 text-center">
                  <b><span class="color_default mt-4">Total Fasyankes</span> :
                    {{ summary.total }}
                  </b>
                </p>
              </v-card>
            </div>
          </div>
          <div class="pa-3 d-flex justify-center">
            <v-btn
              v-if="!loading"
              class="px-15"
              @click="print"
            >
              Print
            </v-btn>
            <v-btn
              v-if="loading"
              class="px-15 text-capitalize"
            >
              Loading...
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PrintWaste",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      role: "",
      loading: false,
      output: null,
    };
  },
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
      summary: (state) => state.report.waste_monthly_summary,
    }),
  },
  methods: {
    async print() {
      this.loading = true;
      const el = this.$refs.printContainer;
      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);
      let a = document.createElement("a"); //Create <a>
      a.href = this.output;
      a.download = `${this.options.chart.type}.png`; //File name Here
      a.click(); //Downloaded file
      this.loading = false;
    },
    generateSeries() {
      const {
        data: {
          meet_criteria,
          not_meet_criteria,
        } = {},
      } = this.summary?.data.find(({ type }) => type === this.type) || {};

      return [meet_criteria, not_meet_criteria];
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>